import { Labor } from "../interface/labor";
import { Utilities } from "./utilities";

export class LaborUtilities extends Utilities {

  static changed(a: Labor, b: Labor): boolean {
    return !this.equalString(a.description, b.description)
      || !this.equalString(a.code, b.code)
      || !this.equalNumberWithDefault(a.saleValue, b.saleValue, 0)
      || a.group !== b.group
      || a.serviceType !== b.serviceType
      || a.KmToChange !== b.KmToChange
      || a.monthToChange !== b.monthToChange
      || a.free !== b.free
      || a.LCCode !== b.LCCode
      || a.CNAE !== b.CNAE
  }

  static complyAPI(labor: Labor): any {
    const resp = {
      id: labor.id,
      description: labor.description,
      serviceType: labor.serviceType,
      code: labor.code,
      saleValue: this.truncValue(labor.saleValue),
      KmToChange: Number(labor.KmToChange) || '',
      monthToChange: labor.monthToChange,
      LCCode: Number(labor.LCCode),
      free: labor.free,
      CNAE: labor.CNAE,
      group: labor.groupObject ? labor.groupObject._id : "",
    };
    for (const key of Object.keys(resp)) {
      if ([undefined, null, ""].includes(resp[key]) || Number.isNaN(resp[key])) {
        delete resp[key];
      }
    }
    if (!resp.LCCode) {
      resp.LCCode = '' as any;
    }
    return resp;
  }

}
